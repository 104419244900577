define(['app', 'cookie'], function (app, cookie) {

  const cookieModal = function () {
    let self = this;
    self.app = app;

    const _config = {
      cookieButton: '[data-js-element=cookie-button]',
      cookie: '[data-js-element=cookie-element]',
      cookieMessageLink: '[data-js-element=cookie-message]',
      cookieName: 'cookieNoticeShown',
      cookieGroup: cookie.group.REQUIRED
    };

    const _init = (element) => {
      self.element = element;
      self.cookieButton = document.querySelector(self.config.cookieButton);
      self.cookieElement = document.querySelector(self.config.cookie);
      self.cookieMessageLink = document.querySelector(self.config.cookieMessageLink);

      self.checkCookieExists();
      return self;
    };

    const _getTotalDaysForNextTwelveMonth = () => {
      // get current time
      const time = new Date();
      const currentDate = time.getDate();
      const currentMonth = time.getMonth() + 1;
      const currentYear = time.getFullYear();

      //get this month totals days and substract from current date
      const currentMonthTotalDays = self.daysInMonth(currentMonth, currentYear);
      const firstMonthRemainingDays = currentMonthTotalDays - currentDate;

      let totalDaysForNextElevenMonths = 0;
      // get total days in the next 11 months
      for (var i = 1; i <= 11; i++) {
        totalDaysForNextElevenMonths += self.daysInMonth((currentMonth + i), currentYear);
      }

      // get the remaining days from the last 12th month - they would be equivalent to the number of the current date
      // get all days together to get the 6 months period
      const twelveMonthsPeriod = firstMonthRemainingDays + totalDaysForNextElevenMonths + currentDate;

      return twelveMonthsPeriod;
    };

    // get total days in the months
    const _daysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    // create the cookie
    const _cookieButtonHandle = () => {
      cookie.set(self.config.cookieName, self.config.cookieGroup, self.config.cookieName, { expires: self.getTotalDaysForNextTwelveMonth(), path: '/' });
      app.publish('tracking/record', 'Cookie Modal', 'Clicked', 'Accept Cookie Button');
      app.publish('columbo/track', 'Cookie Modal', 'Clicked', 'Accept Cookie Button');
      self.cookieElement.style.display = 'none';
    };

    // if cookie exists hide element, if not show it and wait for click event
    const _checkCookieExists = () => {
      if (cookie.get(self.config.cookieName)) {
        self.cookieElement.style.display = 'none';
      } else {
        app.publish('tracking/record', 'Cookie Modal', 'Shown', 'Cookie Modal Message');
        app.publish('columbo/track', 'Cookie Modal', 'Shown', 'Cookie Modal Message');
        self.cookieButton.addEventListener('click',self.cookieButtonHandle);
        self.trackCookieLinkListener();
        self.cookieElement.style.display = 'flex';
      }
    };

    // track click on cookie link to page
    const _trackCookieLinkListener = () => {
      self.cookieMessageLink.addEventListener('click', self.trackCookieLink);
    };

    const _trackCookieLink = () => {
      app.publish('tracking/record', 'Cookie Modal', 'Clicked', 'Link to Cookie Page');
      app.publish('columbo/track', 'Cookie Modal', 'Clicked', 'Link to Cookie Page');
    };

    self.config = _config;
    self.init = _init;
    self.getTotalDaysForNextTwelveMonth = _getTotalDaysForNextTwelveMonth;
    self.daysInMonth = _daysInMonth;
    self.trackCookieLinkListener = _trackCookieLinkListener;
    self.trackCookieLink = _trackCookieLink;
    self.checkCookieExists = _checkCookieExists;
    self.cookieButtonHandle = _cookieButtonHandle;
  };

  return cookieModal;
});
